import { useNavigate, useSearchParams } from "react-router-dom"
import { LinearProgress } from "@mui/material/";
import { Api } from "./api";
import { AddAlert } from "./alert";
import Page from "../components/page";
import { AppState } from "../App";


export const StripeProcessor = (props: {setAppState: (state: AppState) => void}) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const sessionId = searchParams.get("session_id");

    const completeBilling = async () => {
        try {
            await Api.BillingComplete(sessionId!);
            props.setAppState("APP");
            navigate("/");
        } catch (e: any){
            AddAlert({
                message: e.message,
                severity: "error"
            });
        }
    }

    const updateBilling = async () => {
        try {
            await Api.BillingUpdate();
            navigate("/");
        } catch (e: any){
            AddAlert({
                message: e.message,
                severity: "error"
            });
        }
    }

    if(sessionId !== null){
        completeBilling();
    }else{
        updateBilling();
    }

    return <Page title="Processing">
        <h3>Setting up billing</h3>
        <div><LinearProgress /></div>
    </Page>
}