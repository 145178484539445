import { Avatar, Box, Button, Checkbox, FormControlLabel, Grid, LinearProgress, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useState } from 'react';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { TextInput } from '../../components/form';
import { Api } from '../../shared/api';
import { AddAlert } from '../../shared/alert';

const CreateAccountPage = () => {
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = async (values: { name: string, licences: number, promotion?: string }) => {
        setSubmitting(true);

        try {
            const response = await Api.CreateAccount(values.name);
            const billingResponse = await Api.BillingCreate(values.licences, response.id, values.promotion);
            window.location.replace(billingResponse.url);
        } catch (e: any) {
            AddAlert({
                message: e.message,
                severity: "error"
            });
            setSubmitting(false);
        } finally {
        }
    };

    return <>
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Setup your account
            </Typography>
            <Formik initialValues={{ name: "", licences: 1 }}
                    onSubmit={(values) => onSubmit(values)}>
                {({
                      handleSubmit, values
                  }) => (
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ marginBottom: 4 }}>
                                <TextInput
                                    label="Account name"
                                    name="name"
                                    type="text"
                                    disabled={submitting}
                                    required
                                    helperText='This is your company or studio name.'
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ marginBottom: 4 }}>
                                <TextInput
                                    label="Game licences"
                                    name="licences"
                                    type="number"
                                    disabled={submitting}
                                    required
                                    helperText='The amount of games you want to be able to add to your account. (This can be changed at any time.)'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    label="Promotion Code"
                                    name="promotion"
                                    type="text"
                                    disabled={submitting}
                                />
                                <Typography
                                    variant="h4"
                                    sx={{ marginTop: 2 }}>
                                    Monthly cost: ${20 * values.licences}AUD
                                </Typography>
                            </Grid>
                        </Grid>
                        { submitting && <LinearProgress sx={{ mt: 2, mb: 2}} /> }
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={submitting}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Setup
                        </Button>
                    </Box>
                )}
            </Formik>
        </Box>
    </>;
};

export default CreateAccountPage;
