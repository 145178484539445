import { Stream } from "@dragonsight/models";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AddAlert } from "../../shared/alert";
import { Api } from "../../shared/api";
import { Grid, LinearProgress, styled } from "@mui/material";
import ContentContainer from "../../components/contentContainer";
import { token } from "../../shared/theme";

const Image = styled('img')({
    maxWidth: "100%"
})

const UserLink = styled('a')({
    color: token.color.link.primary
})

const StreamTitle = styled('h3')({
    margin: "0"
})

const StreamDetails = styled('div')({
    fontSize: "0.8em",
    gap: "5px"
})

const GameStreams = () => {
    const { gameId } = useParams();
    const [ loading, setLoading ] = useState(false);
    const [ streams, setStreams ] = useState<Stream[]>([]);

    useEffect(() => {
        const getStreams = async () => {
            setLoading(true);

            try {
                const response = await Api.Game.Streams(gameId!);
                setStreams(response);
            } catch (e: any) {
                AddAlert({
                    message: "Streams failed to load",
                    severity: "error"
                })
            } finally {
                setLoading(false);
            }
        }

        getStreams();
    }, []);

    
    return <ContentContainer>
        {loading ? <LinearProgress  /> : <>
            {streams.length === 0 && <>No streams currently</>}
            <Grid container spacing={1}>
                {streams.map((stream) => {
                    const url = stream.thumbnail_url.replace("{width}","300").replace("{height}", "166");
                    const twitchUrl = `https://twitch.tv/${stream.user_login}`;
                    return <Grid item xs={3}><div key={stream.started_at}>
                        <a href={twitchUrl} about="_blank"><Image src={url} /></a>
                        <StreamDetails>
                            <UserLink href={twitchUrl} about="_blank">{stream.user_login}</UserLink>
                            {" - "}<span>0 views</span>
                        </StreamDetails>
                        <StreamTitle>{stream.title}</StreamTitle>
                    </div></Grid>
                })}
            </Grid>
        </>}
    </ContentContainer>
}

export default GameStreams;