import CopyIcon from '@mui/icons-material/ContentCopy';

import { styled } from "@mui/material";
import { token } from "../shared/theme";


export interface CopyBoxProps {
    content: string;
    hideContent?: boolean
}

const Container = styled("div")({
    display: "flex",
    alignItems: "center"
})

const CopyBoxContent = styled("div")({
    backgroundColor: token.color.form.background.normal,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: token.color.form.border.normal,
    borderRadius: "3px",
    padding: token.spacing.s200,
    marginRight: token.spacing.s300,
    "&:hover, &:focus": {
        borderColor: token.color.form.border.active,
    }
})

const IconButton = styled("button")({
    padding: "0",
    background: "transparent",
    border: "0",
    outline: "0",
    color: "#fff",
    cursor: "pointer",
    ":hover": {
        color: "#ddd"
    }
});

const CopyBox = (props: CopyBoxProps) => {
    const {content, hideContent} = props;

    const copyToClipboard = async (text: string) => {
        await navigator.clipboard.writeText(text);
    };
    return <Container>
        {hideContent !== false && <CopyBoxContent>{content}</CopyBoxContent>}
        <IconButton onClick={() => copyToClipboard(content)} ><CopyIcon/></IconButton>
    </Container>
}

export default CopyBox;