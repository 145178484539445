import { Box, Collapse, Divider, Stack, Typography } from "@mui/material";
import { ReactNode, useState } from "react";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { token } from "../shared/theme";

export interface SectionHeaderProps {
    children?: ReactNode;
    collapse?: boolean;
    startCollapsed?: boolean;
    header: string;
}

const Section = (props: SectionHeaderProps) => {
    const { header, children, collapse, startCollapsed } = props;

    const [collapsed, setCollapsed] = useState(startCollapsed ?? true);


    return <Box>
        <Stack borderBottom="1px solid #fff" paddingBottom={token.spacing.s400} direction="row" onClick={() => setCollapsed(!collapsed)}>
            <Typography fontWeight={700} flexGrow={1}>{header}</Typography> {collapse && (collapsed ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon /> )}
        </Stack>
        { collapse === true ?
            <Collapse in={!collapsed}><Box paddingTop={token.spacing.s400}>
                {children}
                </Box>
            </Collapse>
            : <Box paddingTop={token.spacing.s400}>{children}</Box>
    }
    </Box>
}

export default Section