import { styled } from "@mui/material";


const IconButton = styled("button")((props: {disabled: boolean}) => ({
    padding: "0",
    background: "transparent",
    border: "0",
    outline: "0",
    color: props.disabled ? "#aaa" : "#fff",
    cursor: props.disabled ? "now-allowed" : "pointer",
    ":hover": {
        color: "#ddd"
    }
}));

export default IconButton;