import { API } from 'aws-amplify';
import { Account, DiscordDisplay, Game, YoutubeListResponseItem, Lead, GameFeedback, GameFeedbackChannel, WebHook, WebHookCreateRequest, Stream } from '@dragonsight/models';
import { getCurrentAccount } from './appContext';
import { AddAlert } from './alert';
import { Feedback } from '@mui/icons-material';
import { List } from '@mui/material';

interface TokenizedItems<T> {
    items: T[];
    nextToken?: string;
}

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const Api = {
    GetAccount: async (): Promise<Account | undefined> => {
        const response = await API.get("DragonSightApi", "account", {});
        if(response !== undefined){
            return response;
        }
        throw new Error("Account not found");
    },
    CreateAccount: async (name: string): Promise<Account> => {
        const response = await API.post("DragonSightApi", "account", {
            body: {name}
        });
        if(response !== undefined){
            return response;
        }
        throw new Error("Could not create account");
    },

    DiscordLink: async (token: string): Promise<any> => {

        const response = await API.post("DragonSightApi", "discord/link", {
            body: {token},
            headers: {
                "account": getCurrentAccount().id
            }
        });
        if(response !== undefined) {
            return response;
        }
        throw new Error("Could not link discord");
    },

    DiscordAdd: async (guildId: string): Promise<any> => {

        const response = await API.post("DragonSightApi", "discord", {
            body: {guildId},
            headers: {
                "account": getCurrentAccount().id
            }
        });
        if(response !== undefined) {
            return response;
        }
        throw new Error("Could not add server");
    },

    DiscordDelete: async (guildId: string): Promise<any> => {
        const response = await API.del("DragonSightApi", "discord", {
            body: {guildId},
            headers: {
                "account": getCurrentAccount().id
            }
        });
        if(response !== undefined) {
            return response;
        }
        throw new Error("Could not delete server");
    },

    DiscordServers: async (): Promise<DiscordDisplay[]> => {
        const response = await API.post("DragonSightApi", "discord/servers", {
            headers: {
                "account": getCurrentAccount().id
            }
        });
        if(response !== undefined) {
            return response;
        }
        throw new Error("Could not get discord servers");
    },

    BillingCreate: async (licenceQuantity: number, accountId: string, promotion?: string): Promise<any> => {
        const response = await API.post("DragonSightApi", "billing", {
            body: {
                "licence_quantity": licenceQuantity.toString(),
                "promotion_code": promotion
            },
            headers: {
                "account": accountId
            }
        });
        if (response !== undefined) return response;
        throw new Error("Could not create billing session")
    },

    BillingComplete: async (sessionId: string): Promise<any> => {
        const response = await API.put("DragonSightApi", "billing", {
            body: {
                "session_id": sessionId
            },
            headers: {
                "account": getCurrentAccount().id
            }
        });
        if (response !== undefined) return response;
        throw new Error("Could not complete billing session")
    },

    BillingUpdate: async (): Promise<any> => {
        const response = await API.patch("DragonSightApi", "billing", {
            headers: {
                "account": getCurrentAccount().id
            }
        });
        if (response !== undefined) return response;
        throw new Error("Could not update billing")
    },

    BillingPortal: async (): Promise<any> => {
        const response = await API.post("DragonSightApi", "billing/portal", {
            headers: {
                "account": getCurrentAccount().id
            }
        });
        if (response !== undefined) return response;
        throw new Error("Could not create portal session")
    },

    GameRead: async (id: string): Promise<Game> => {
        const response = await API.get("DragonSightApi", `game/${id}`, {
            headers: {
                "account": getCurrentAccount().id
            }
        });

        return response as Game
    },

    GameCreate: async (game: {
        name: string,
        description?: string,
        notes?: string
    }): Promise<Game> => {
        const response = await API.post("DragonSightApi", "game", {
            body: {
                name: game.name,
                description: game.description,
                notes: game.notes,
            },
            headers: {
                "account": getCurrentAccount().id
            }
        });

        return response as Game
    },

    GameDelete: async (id: string): Promise<any> => {
        const response = await API.del("DragonSightApi", `game/${id}`, {
            headers: {
                "account": getCurrentAccount().id
            }
        });
    },

    GameUpdate: async (game: {
        id: string,
        name?: string,
        description?: string,
        notes?: string,
        twitchId?: string,
        youtubeId?: string
    }): Promise<Game> => {
        const response = await API.patch("DragonSightApi", `game/${game.id}`, {
            body: {
                name: game.name,
                description: game.description,
                notes: game.notes,
                twitchId: game.twitchId,
                youtubeId: game.youtubeId,
            },
            headers: {
                "account": getCurrentAccount().id
            }
        });

        return response as Game
    },

    GameList: async (): Promise<Game[]>=> {
        const response = await API.get("DragonSightApi", "game", {
            headers: {
                "account": getCurrentAccount().id
            }
        });

        return response.items as Game[];
    },

    YoutubeList: async (gameId: string): Promise<YoutubeListResponseItem[]> => {
        const response = await API.get("DragonSightApi", `game/${gameId}/youtube`, {
            headers: {
                "account": getCurrentAccount().id
            }
        });

        return [...response] as YoutubeListResponseItem[];
    },

    Game: {
        ListFeedback: async (gameId: string, channelId?: string, nextToken?: string, limit?: number): Promise<TokenizedItems<GameFeedback>> => {
            const options = [];
            if(nextToken !== undefined){
                options.push(`nextToken=${nextToken}`);
            }
            if(limit !== undefined){
                options.push(`limit=${limit}`);
            }
            if(channelId !== undefined) {
                options.push(`channelId=${channelId}`);
            }
            
            const response = await API.get("DragonSightApi", `gamefeedback/${gameId}?${options.join("&")}`, {
                headers: {
                    "account": getCurrentAccount().id
                }
            });

            return {
                items: response.items,
                nextToken: response.nextToken
            }
        },
        EditFeedback: async (gameId: string, id: string, flagged: boolean): Promise<void> => {
            await API.patch("DragonSightApi", `gamefeedback/${gameId}/${id}`, {
                headers: {
                    "account": getCurrentAccount().id
                },
                body: {
                    flagged
                }
            })
            
        },
        RemoveFeedback: async (gameId: string, id: string): Promise<void> => {
            await API.del("DragonSightApi", `gamefeedback/${gameId}/${id}`, {
                headers: {
                    "account": getCurrentAccount().id
                }
            });
        },
        FeedbackChannelAdd: async(gameId: string, name: string): Promise<GameFeedbackChannel> => {
            const response = await API.post("DragonSightApi", `gamefeedback/${gameId}/channel`, {
                headers: {
                    "account": getCurrentAccount().id
                },
                body: {
                    name
                }
            });

            if(response === undefined) {
                throw new Error(response.error || "Could not create feedback channel.");
            }

            return response;
        },
        FeedbackChannelList: async(gameId: string, nextToken?: string, limit?: number): Promise<TokenizedItems<GameFeedbackChannel>> => {
            const options = [];
            if(nextToken !== undefined){
                options.push(`nextToken=${nextToken}`);
            }
            if(limit !== undefined){
                options.push(`limit=${limit}`);
            }
            
            const response = await API.get("DragonSightApi", `gamefeedback/${gameId}/channel?${options.join("&")}`, {
                headers: {
                    "account": getCurrentAccount().id
                }
            });

            return {
                items: response.items,
                nextToken: response.nextToken
            }
        },
        FeedbackChanelRemove: async(gameId: string, channelId: string):Promise<void> => {
            const response = await API.del("DragonSightApi", `gamefeedback/${gameId}/channel/${channelId}`, {
                headers: {
                    "account": getCurrentAccount().id
                }
            });

            if(response === undefined) {
                throw new Error(response.error || "Could not delete feedback channel.");
            }

            return response;
        },
        Streams: async(gameId: string): Promise<Stream[]> => {
            const response = await API.get("DragonSightApi", `game/${gameId}/streams`, {
                headers: {
                    "account": getCurrentAccount().id
                }
            })

            return response;
        }
    },

    Crm: {
        List: async (search: string, nextToken?: string, limit?: number): Promise<TokenizedItems<Lead>> => {
            const options = [];
            if(nextToken !== undefined){
                options.push(`nextToken=${nextToken}`);
            }
            if(limit !== undefined){
                options.push(`limit=${limit}`);
            }
            const response = await API.get("DragonSightApi", `crm/lead?${options.join("&")}`, {
                headers: {
                    "account": getCurrentAccount().id
                }
            });
            if(response.items === undefined) {
                throw new Error(response.error || "Could not retrieve leads.");
            }

            return {
                items: response.items,
                nextToken: response.nextToken
            }
        },
        Add: async (lead: Omit<Lead,"id" | "accountId">): Promise<Lead> => {
            const response = await API.post("DragonSightApi", `crm/lead`, {
                headers: {
                    "account": getCurrentAccount().id
                },
                body: lead,
            });
            
            return response;
        },
        Edit: async (lead: Lead): Promise<Lead> => {
            const response = await API.patch("DragonSightApi", `crm/lead/${lead.id}`, {
                headers: {
                    "account": getCurrentAccount().id
                },
                body: {
                    name: lead.name,
                    company: lead.company,
                    note: lead.note,
                    platforms: lead.platforms,
                    contacts: lead.contacts,
                    messages: lead.messages,
                },
            });
            
            return response;
        },
        Delete: async (id: string) => { 
            await API.del("DragonSightApi", `crm/lead/${id}`, {
                headers: {
                    "account": getCurrentAccount().id
                }
            });
        }
    },

    Webhook: {
        List: async (search: string, nextToken?: string, limit?: number): Promise<TokenizedItems<WebHook>> => {
            const options = [];
            if(nextToken !== undefined){
                options.push(`nextToken=${nextToken}`);
            }
            if(limit !== undefined){
                options.push(`limit=${limit}`);
            }
            const response = await API.get("DragonSightApi", `webhook?${options.join("&")}`, {
                headers: {
                    "account": getCurrentAccount().id
                }
            });
            if(response.items === undefined) {
                throw new Error(response.error || "Could not retrieve leads.");
            }

            return {
                items: response.items,
                nextToken: response.nextToken
            }
        },
        Add: async (webhook: WebHookCreateRequest): Promise<WebHook> => {
            const response = await API.post("DragonSightApi", `webhook`, {
                headers: {
                    "account": getCurrentAccount().id
                },
                body: webhook,
            });
            
            return response;
        },
        AddGame: async (webhookId: string, gameId: string) => {
            const response = await API.post("DragonSightApi", `webhook/${webhookId}/${gameId}`, {
                headers: {
                    "account": getCurrentAccount().id
                },
            });

            return response;
        },
        RemoveGame: async (webhookId: string, gameId: string) => {
            const response = await API.del("DragonSightApi", `webhook/${webhookId}/${gameId}`, {
                headers: {
                    "account": getCurrentAccount().id
                },
            });

            return response;
        },
        // Edit: async (lead: WebHook): Promise<Lead> => {
        //     const response = await API.patch("DragonSightApi", `crm/lead/${lead.id}`, {
        //         headers: {
        //             "account": getCurrentAccount().id
        //         },
        //         body: {
        //             name: lead.name,
        //             company: lead.company,
        //             note: lead.note,
        //             platforms: lead.platforms,
        //             contacts: lead.contacts,
        //             messages: lead.messages,
        //         },
        //     });
            
        //     return response;
        // },
        Delete: async (id: string) => { 
            await API.del("DragonSightApi", `webhook/${id}`, {
                headers: {
                    "account": getCurrentAccount().id
                }
            });
        }
    }
}

const leads: Lead[] = [];