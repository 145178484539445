import { Box, Card, CardActions, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, LinearProgress, Link, styled, Typography } from "@mui/material/";
import Page from "../../components/page";
import { Game } from "@dragonsight/models";
import { useEffect, useState } from "react";
import { AddAlert } from "../../shared/alert";
import { Api } from "../../shared/api";
import { Formik } from "formik";
import { TextInput } from "../../components/form";
import { useNavigate } from "react-router";
import Button from "../../components/button";

import { token } from "../../shared/theme";

interface GameValues {
    name: string;
    description?: string;
    notes?: string;
}

const CardX = styled("div")({
    background: token.color.panel.background,
    borderRadius: "5px",
    "&:hover, &:focus": {
        background: token.color.panel.backgroundActive,
    }
})

const GameCard = (props: {game: Game, onDelete: (id: string) => void}) => {
    const navigate = useNavigate();
    return <Grid
        item
        xs={12}
        sm={6}
        md={4}
    >
        <CardX tabIndex={0} onClick={() => navigate(`/game/${props.game.id}`)} sx={{ cursor: "pointer"}}>
        <CardHeader title={props.game.name} />
        <CardContent>
            <Typography variant="body1">
                {props.game.description}
            </Typography>
        </CardContent>
        <CardActions>
            <Button onClick={() => navigate(`/game/${props.game.id}`)}>Manage</Button>
        </CardActions>
    </CardX>
    </Grid>
}

const AddGameDialog = (props: {
        show: boolean,
        onClose: () => void,
        onGameAdded: (game: Game) => void,
    }) => {
    const [ submitting, setSubmitting ] = useState(false);
    const { show, onClose, onGameAdded } = props;

    const AddGame = async (values: GameValues) => {
        setSubmitting(true);
        try {
            const response = await Api.GameCreate({
                name: values.name, description: values.description, notes: values.notes
            });
            AddAlert({
                severity: "success",
                message: `${response.name} has been added.`
            })
            onGameAdded(response);
            onClose();
        } catch(e:any) {
            AddAlert({
                severity: "error",
                message: e.message,
            })
        } finally {
            setSubmitting(false);
        }
    }

    return <Dialog open={show} onClose={() => onClose()}>
        <DialogTitle>Add New Game</DialogTitle>
            <Formik initialValues={{ name: "", description: "", notes: "" }}
                onSubmit={(values) => AddGame(values)}>
                {({
                    handleSubmit, values
                }) => (<>
                <DialogContent>
                    <DialogContentText>
                        <p>
                            Please visit <Link href={"https://help.twitch.tv/s/article/adding-a-game-and-box-art-to-the-directory?language=en_US"}></Link> to add your game to twitch's API if you havent done so yet.
                        </p>
                    </DialogContentText>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ marginBottom: 3 }}>
                                    <TextInput
                                        label="name"
                                        name="name"
                                        type="text"
                                        disabled={submitting}
                                        required
                                        helperText="The name of your game, we will also search for this on Twitch so we can link this up for you. (You can manually do this later if it doesnt automatically find your game)"
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ marginBottom: 3 }}>
                                    <TextInput
                                        label="Description"
                                        name="description"
                                        type="text"
                                        disabled={submitting}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Notes"
                                        name="notes"
                                        type="text"
                                        disabled={submitting}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onClose()}>Cancel</Button>
                        <Button onClick={() => handleSubmit()}>Add</Button>
                    </DialogActions>
                </>)}
            </Formik>
        </Dialog>
}

export const HomeDashboardPage = () => {
    const [loading, setLoading] = useState(false);
    const [games, setGames] = useState<Game[]>([]);
    const [showAddGame, setShowAddGame] = useState(false);

    useEffect(() => {
        const getGames = async () => {
            setLoading(true);
            try{
                const response = await Api.GameList();
                setGames(response);
            } catch(e: any) {
                AddAlert({
                    severity: "error",
                    message: e.message
                })
            }
            finally {
                setLoading(false);
            }
        }
        getGames();
    }, []);

    const content = () => {
        return <>
            <Grid container spacing={5} alignItems="flex-end">
                {games.map((game) => {
                    return <GameCard game={game} key={game.id} onDelete={() => { AddAlert({ severity: "info", message:"Not implemented"})}} />
                })}
            </Grid>
            
            <AddGameDialog show={showAddGame} onClose={() => setShowAddGame(false)} onGameAdded={(game) => setGames((prev) => { return [ ...prev, game]})} />            
        </>
    }

    return <Page title="Games" breadcrumbs={[
        { label: "Home", to: "/"},
    ]}>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          Use the <b>DragonSight</b> to manage your games.
        </Typography>
        <Grid container spacing={1}>
            <Grid item>
                <Button variant="contained" onClick={() => setShowAddGame(true)}>Add Game</Button>
            </Grid>
        </Grid>
        <Box sx={{ mt: 6, mb: 6 }}>
            {loading ? <LinearProgress  /> : content()}
        </Box>
    </Page>
}