import { useEffect, useState } from "react";
import SignUpPage from "./signup";
import { Hub, Auth } from 'aws-amplify';
import { CognitoUser, ISignUpResult } from 'amazon-cognito-identity-js';
import ConfirmEmailPage from "./confirmEmail";
import SignInPage from "./signin";
import ForgotPage from "./forgot";
import { AddAlert } from "../../shared/alert";
import ResetPasswordPage from "./resetPassword";

type AuthPageType = "SignIn" | "SignUp" | "ConfirmEmail" | "Forgot" | "ResetPassword"

export interface AuthPageProps {
    page: AuthPageType;
    onUserSignIn: (user: CognitoUser) => void;
}

const AuthPage = (props: AuthPageProps) => {
    const params = new URLSearchParams(window.location.search);
    const path = window.location.pathname.split("/");
    let defaultPage: AuthPageType = props.page;
    if(path.length > 1 && path[1] === "register") defaultPage = "SignUp";
    const confirmEmail = params.get("confirmEmail");
    if(confirmEmail) defaultPage = "ConfirmEmail";
    const resetPassword = params.get("resetPassword");
    if(resetPassword) defaultPage = "ResetPassword";

    const [ username, setUsername ] = useState<string | undefined>(confirmEmail ?? resetPassword ?? undefined);

    const [ page, setPage ] = useState(defaultPage);
    
    const listener = (data: any) => {
        console.dir(data);
        switch (data.payload.event) {
            case 'signIn_failure':
                switch (data.payload.data.code){
                    case 'NotAuthorizedException':
                    case 'UserNotFoundException':
                        AddAlert({ severity: "warning", message: "Email or password incorrect"});
                        break;
                    case 'UserNotConfirmedException':
                        setPage("ConfirmEmail");
                        break;
                    case 'PasswordResetRequiredException':
                        setPage("ResetPassword")
                        break;
                }
                break;
            case 'autoSignIn':
                var user = data.payload.data as CognitoUser;
                props.onUserSignIn(user);
                break;
            case 'signUp':
                var signupResult = data.payload.data as ISignUpResult;
                var user = signupResult.user;
                setUsername(user.getUsername());
                if(!signupResult.userConfirmed) {
                    setPage("ConfirmEmail");
                }
                break;
            case 'confirmSignUp':
                //console.log('user confirmation successful', data.payload);
                break;
            
        }
    }

    useEffect(() => {
        var unSubsccribe = Hub.listen('auth', listener);
        return () => {
            unSubsccribe();
        }
    }, []);

    switch (page) {
        case "SignIn":
            return <SignInPage
                setUsername={(username) => setUsername(username)}
                onSwitchToSignUp={() => setPage("SignUp")} 
                onSwitchToForgot={() => setPage("Forgot")}
                onUserSignedIn={(user) => props.onUserSignIn(user)}
                />;
        case "Forgot":
            return <ForgotPage onSwitchToSignIn={() => setPage("SignIn")} />;
        case "SignUp":
            return <SignUpPage onSwitchToSignIn={() => setPage("SignIn") } />;
        case "ConfirmEmail":
            return <ConfirmEmailPage user={username!} />;
        case "ResetPassword":
            return <ResetPasswordPage user={username!} onComplete={() => setPage("SignIn")} />
    }
}

export default AuthPage;